.receiptItem{
    display: flex;
    max-height: 70px;
    font-size: 20px;
    padding: 10px 20px; 
    border-top: 1px solid rgba(226, 226, 226, 0.74);
    
}

.itemTitle{
    width: 40%;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
}

.itemQuantity{
    display: flex;
    justify-content: space-around;
    width: 30%;
}
.icons{
    font-size: 28px;
    border-radius: 5px;
    box-shadow: 0 0 20px rgb(0 0 0 / 15%);
    font-weight: bold;
    cursor: pointer;
    user-select: none;

}

.icons:hover{
    background-color: rgba(240, 255, 255, 0.364);
}

.icons:active{
    background-color: rgba(240, 255, 255, 0.571);
}

.itemPrice{
    font-weight: bold;
    text-align: right;
    padding-right: 20px;
    width: 25%; 
}