.listItem{
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: baseline;
    width: 100%;
    padding: 10px 10px;
    max-height: 100px;
    border-top: 1px solid rgba(226, 226, 226, 0.74);
    cursor: pointer;
    user-select: none;

}
.listItem:hover{
    background-color: rgba(240, 255, 255, 0.364);
}

.itemTitle{
    font-size: 24px;
    padding-right: 20px;
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
}

.itemPrice{
    font-size: 20px;
    font-weight: bold;
}